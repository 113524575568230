import React, { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { TransactionSchema, TransactionType } from "client/data-contracts";
import TransactionsTagsDisplay from "components/Finances/Transactions/TransactionsTagsDisplay";
import { DEFAULT_CURRENCY, displayMoneyWithCurrency } from "utils/finances";

interface TransactionsListItemProps {
  item: TransactionSchema;
  deleteTransaction(entityId: string, setDeleteButtonDisabled: Dispatch<SetStateAction<boolean>>): void;
}

function transactionClass(item: TransactionSchema): string {
  if (item.type === TransactionType.INCOME) {
    return "text-success";
  }
  if (item.type === TransactionType.REGULAR) {
    return "text-danger";
  }
  return "text-secondary";
}

function transactionPrefix(item: TransactionSchema): string {
  if (item.type === TransactionType.INCOME) {
    return "+";
  }
  if (item.type === TransactionType.REGULAR) {
    return "-";
  }
  return "";
}

export default function TransactionsListItem({
  item,
  deleteTransaction,
}: TransactionsListItemProps): React.JSX.Element {
  const navigate = useNavigate();
  function editAction() {
    const type = item.type === TransactionType.REGULAR || TransactionType.INCOME ? "regular" : "transfer";
    navigate(`/finances/transactions/edit/${type}/${item.id}`);
  }

  function deleteAction() {
    const confirmMessage = `Are you sure you want to delete transaction: ${displayMoneyWithCurrency(item.amount, item.currency)}?`;
    if (window.confirm(confirmMessage)) {
      deleteTransaction(item.id as string, () => {});
    }
  }

  return (
    <div className="row transactions-item" data-test="transactions-item">
      <div className="col-4 col-md-2">
        <div className="d-inline-block transactions-categories">
          <span className="text-secondary">{item.account?.name}</span>
          <br />
          {item.category?.name}
          {item.from_income?.name}
          {item.to_account && item.to_account?.name}
        </div>
      </div>
      <div className="col-3 col-md-1 transaction-amount">
        <span className={`${transactionClass(item)}`}>
          {transactionPrefix(item)}
          {displayMoneyWithCurrency(item.amount, item.currency)}
        </span>
        {item.equivalent_amount_in_usd &&
          item.type !== TransactionType.TRANSFER &&
          item.currency !== DEFAULT_CURRENCY && (
            <>
              <br />
              <span className="text-secondary fs-7">
                ~{displayMoneyWithCurrency(item.equivalent_amount_in_usd, DEFAULT_CURRENCY)}
              </span>
            </>
          )}
        {item.to_amount && item.to_currency && item.to_currency !== item.currency && (
          <span className={`${transactionClass(item)}`}>
            <br /> {displayMoneyWithCurrency(item.to_amount, item.to_currency)}
          </span>
        )}
      </div>
      <div className="col-4 col-md-4">
        {item.comment && (
          <>
            <span className="transaction-comment text-secondary fs-7"> {item.comment}</span>
            {item.tags && <br />}
          </>
        )}
        {item.tags && <TransactionsTagsDisplay tags={item.tags} />}
      </div>
      <div className="col-1">
        <button
          type="button"
          data-test="transaction-item-edit"
          className="btn btn-sm btn-outline-primary mb-1"
          onClick={() => {
            editAction();
          }}
        >
          <span className="d-none d-sm-block">Edit</span>
          <span className="d-block d-sm-none" style={{ width: "12px" }}>
            E
          </span>
        </button>
        <button
          type="button"
          data-test="transaction-item-delete"
          className="btn btn-sm btn-outline-danger"
          onClick={() => {
            deleteAction();
          }}
        >
          <span className="d-none d-sm-block">Delete</span>
          <span className="d-block d-sm-none" style={{ width: "12px" }}>
            D
          </span>
        </button>
      </div>
    </div>
  );
}
