import React, { useEffect, useState } from "react";
import BookRawContent from "components/Media/Book/BookRawContent";
import BookReadingHistory from "components/Media/Book/BookReadingHistory";
import BookUpdateInfo from "components/Media/Book/BookUpdateInfo";
import { useParams } from "react-router-dom";
import api from "utils/api";
import { BookSchema } from "client/data-contracts";
import { Nullish } from "utils/base";
import type { AxiosError } from "axios";
import DisplayError from "components/Common/DisplayError";
import { displayMainTitle, displayOtherTitles } from "utils/media/book";
import BookExternalLinks from "components/Media/Book/BookExternalLinks";

export default function BookView(): React.JSX.Element {
  const { bookId } = useParams();
  const [book, setBook] = useState<BookSchema | null>(null);
  const [requestError, setRequestError] = useState<Nullish<AxiosError>>(undefined);
  async function deleteBookHandler() {
    if (!book) {
      return;
    }

    if (window.confirm("Are you sure you want to delete this book?")) {
      try {
        await api.books.deleteBook(book);
        window.location.href = "/media/books";
      } catch (error) {
        setRequestError(error as AxiosError);
      }
    }
  }

  useEffect(() => {
    (async () => {
      const response = await api.books.getBookDetails(bookId as string);
      setBook(response.data);
    })();
  }, [bookId]);

  return (
    <div>
      <DisplayError error={requestError} />
      {book && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <h3 className="mb-2" data-test="books-details-header">
                {displayMainTitle(book)}
              </h3>
              <div className="text-secondary">{displayOtherTitles(book)}</div>
              <BookExternalLinks book={book} />
            </div>
            <button className="btn btn-outline-danger btn-sm" onClick={deleteBookHandler} type="button">
              Delete this book
            </button>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <BookRawContent book={book} setBook={setBook} setRequestError={setRequestError} />
            </div>

            <div className="col-lg-6">
              <BookReadingHistory book={book} setBook={setBook} setRequestError={setRequestError} />

              <BookUpdateInfo setBook={setBook} setRequestError={setRequestError} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
