import React from "react";
import IncomeForm from "components/Finances/Income/IncomeForm";

export default function IncomeAdd(): React.JSX.Element {
  return (
    <>
      <h3 data-test="income-add-title">Add income</h3>
      <IncomeForm entity={undefined} />
    </>
  );
}
