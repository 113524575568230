import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import api from "utils/api";
import { convertMoneyToNumber } from "utils/finances";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const getColor = (index: number): string => {
  const colors = [
    "#2E86AB", // Steel Blue
    "#F6511D", // Coral Red
    "#7B2CBF", // Royal Purple
    "#00A878", // Emerald Green
    "#FFB400", // Golden Yellow
    "#E63946", // Cherry Red
    "#457B9D", // Slate Blue
    "#43AA8B", // Teal
    "#F94144", // Vermilion
    "#9B5DE5", // Amethyst
  ];
  return colors[index % colors.length];
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CHART_OPTIONS = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: "index" as const,
  },
  scales: {
    y: {
      ticks: {
        callback(value: any) {
          return `$${value}`;
        },
      },
    },
  },
  plugins: {
    legend: {
      position: "top" as const,
      labels: {
        boxWidth: 20,
        boxHeight: 10,
      },
    },
    tooltip: {
      callbacks: {
        label(context: any) {
          return `${context.dataset.label}: $${context.parsed.y.toFixed()}`;
        },
        beforeLabel(context: any) {
          // Sort tooltip items by value
          const items = context.chart.tooltip.dataPoints;
          if (items && items.length > 0 && context.dataIndex === 0) {
            items.sort((a: any, b: any) => {
              // Keep Total Income at top
              if (a.dataset.label === "Total Income") return -1;
              if (b.dataset.label === "Total Income") return 1;
              // Keep Expenses at bottom
              if (a.dataset.label === "Expenses") return 1;
              if (b.dataset.label === "Expenses") return -1;
              // Sort other items by value
              return b.parsed.y - a.parsed.y;
            });
          }
        },
        footer(tooltipItems: any) {
          // Get total income from the first dataset (Total Income)
          const totalIncome = tooltipItems[0].parsed.y;
          // Get expenses from the last dataset
          const expenses = tooltipItems[tooltipItems.length - 1].parsed.y;
          const diff = totalIncome - expenses;
          const sign = diff > 0 ? "+" : "";
          return `Difference: ${sign}$${diff.toFixed()}`;
        },
      },
      itemSort(a: any, b: any) {
        // Keep Total Income at top
        if (a.dataset.label === "Total Income") return -1;
        if (b.dataset.label === "Total Income") return 1;
        // Keep Expenses at bottom
        if (a.dataset.label === "Expenses") return 1;
        if (b.dataset.label === "Expenses") return -1;
        // Sort other items by value
        return b.parsed.y - a.parsed.y;
      },
    },
  },
};

const PERIODS = [
  {
    value: "1y",
    label: "1 year",
  },
  {
    value: "2y",
    label: "2 years",
  },
  {
    value: "3y",
    label: "3 years",
  },
];

export default function IncomeAndExpensesChart(): React.JSX.Element {
  const [chartData, setChartData] = useState<any>(null);
  const [dataPeriod, setDataPeriod] = useState<string>("1y");

  useEffect(() => {
    (async () => {
      const response = await api.finance.getIncomeAndExpensesStatisticsForFinanceGroup({
        period: dataPeriod,
      });
      const results = response.data;

      // Prepare labels (all unique dates from income and expenses)
      const incomeDates = results.income.map((item: any) => item.date);
      const expenseDates = results.expenses.map((item: any) => item.date);
      const labels = Array.from(new Set([...incomeDates, ...expenseDates])).sort();

      // Group income by owner and date
      const incomeByOwner: { [owner: string]: { [date: string]: number } } = {};
      results.income.forEach((item: any) => {
        const owner = item.owner;
        const date = item.date;
        const value = convertMoneyToNumber(item.value);

        if (!incomeByOwner[owner]) {
          incomeByOwner[owner] = {};
        }
        incomeByOwner[owner][date] = value;
      });

      // Prepare datasets for each owner
      const incomeDatasets = Object.keys(incomeByOwner).map((owner, index) => {
        const data = labels.map((date) => incomeByOwner[owner][date] || 0);
        const color = getColor(index);
        const name = owner === "null" ? "Other" : owner;
        return {
          label: name,
          data,
          borderColor: color,
          backgroundColor: color,
          tension: 0.2,
        };
      });

      // Prepare expenses data aligned with labels
      const expensesDataMap: { [date: string]: number } = {};
      results.expenses.forEach((item: any) => {
        expensesDataMap[item.date] = convertMoneyToNumber(item.value);
      });
      const expensesData = labels.map((date) => expensesDataMap[date] || 0);

      // Calculate total income for each date
      const totalIncomeData = labels.map((date) => {
        return Object.values(incomeByOwner).reduce((sum, ownerData) => {
          return sum + (ownerData[date] || 0);
        }, 0);
      });

      // Combine datasets
      const chartDataNotReactive = {
        labels,
        datasets: [
          {
            label: "Total Income",
            data: totalIncomeData,
            borderColor: "#526E48",
            backgroundColor: "#526E48",
            tension: 0.2,
            borderWidth: 2,
          },
          ...incomeDatasets,
          {
            label: "Expenses",
            data: expensesData,
            borderColor: "#D4BDAC",
            backgroundColor: "#D4BDAC",
            tension: 0.2,
          },
        ],
      };
      setChartData(chartDataNotReactive);
    })();
  }, [dataPeriod, setChartData]);

  const periodSwitchers = PERIODS.map((item, index) => (
    <span
      key={item.value}
      className={["me-2", "badge", item.value === dataPeriod ? "text-bg-success" : "text-bg-primary"].join(
        " "
      )}
      role="button"
      tabIndex={index}
      onClick={() => setDataPeriod(item.value)}
    >
      {item.label}
    </span>
  ));

  return (
    <>
      {chartData && (
        <>
          {periodSwitchers}
          <Line options={CHART_OPTIONS} data={chartData} />
        </>
      )}
    </>
  );
}
/* eslint-enable @typescript-eslint/no-explicit-any */
