import React, { useRef, useState, useEffect } from "react";
import api from "utils/api";
import { BookInputSchema } from "client/data-contracts";
import type { AxiosError } from "axios";
import { Nullish } from "utils/base";
import DisplayError from "components/Common/DisplayError";
import { useNavigate } from "react-router-dom";

export default function BookAdd(): React.JSX.Element {
  const navigate = useNavigate();
  const [requestError, setRequestError] = useState<Nullish<AxiosError>>(undefined);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    textareaRef.current?.focus();
  }, []);

  async function handleSubmitEvent(event: React.FormEvent) {
    event.preventDefault();
    setRequestError(null);
    const inputText = textareaRef.current?.value ?? "";

    let bookInput;
    try {
      bookInput = JSON.parse(inputText);
    } catch {
      // if it is not JSON, then it is a URL
      bookInput = {
        url: inputText,
      };
    }

    try {
      const response = await api.books.createOrUpdateBookFromExport(bookInput as BookInputSchema);
      navigate(`/media/books/${response.data.id}`);
    } catch (apiError) {
      setRequestError(apiError as AxiosError);
    }
  }

  return (
    <div className="row">
      <div className="col-lg-6">
        <h3 data-test="books-add-header">Create or update a book</h3>
        <p className="text-secondary">
          Create or update already exists book, all fields will be merged when it is possible.
        </p>

        <form>
          <textarea
            ref={textareaRef}
            rows={20}
            className="form-control"
            placeholder="Paste book JSON data here..."
          ></textarea>
          <div className="text-end">
            <button
              className="btn btn-primary mt-2"
              type="submit"
              onClick={(event) => {
                handleSubmitEvent(event);
              }}
            >
              Add
            </button>
          </div>
        </form>
      </div>

      <div className="col-lg-6">
        <DisplayError error={requestError} />
      </div>
    </div>
  );
}
