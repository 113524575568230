import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { number, object, string } from "yup";
import { FinanceCategory, FinanceCategoryType } from "client/data-contracts";
import DisplayError from "components/Common/DisplayError";
import {
  createFinanceCategory,
  deleteFinanceCategory,
  selectFinanceCategories,
  updateFinanceCategory,
} from "components/Finances/FinanceCategories/FinanceCategoriesSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { isDefined, Nullish } from "utils/base";
import getById from "utils/crud";
import { useNavigate } from "react-router-dom";

interface FinanceCategoriesFormProps {
  entity: FinanceCategory | undefined;
}
interface Values {
  financeCategoryName: string;
  financeCategoryType: string;
  order: number;
}

const validationSchema = object({
  financeCategoryName: string().required("Category name is required."),
  financeCategoryType: string().required("Type is required."),
  order: number().required().positive().min(0),
});

export default function FinanceCategoriesForm({ entity }: FinanceCategoriesFormProps) {
  const defaultValues = (entityForm: FinanceCategory | undefined): Values => {
    return {
      financeCategoryName: entityForm?.name || "",
      financeCategoryType: entityForm?.category_type || "",
      order: 0,
    } as Values;
  };
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const financeCategories = useAppSelector(selectFinanceCategories);
  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));
  useEffect(() => {
    const values = defaultValues(entity);
    values.order = isDefined(entity) && isDefined(entity.order) ? entity.order : financeCategories.length;
    setFormValues(values);
  }, [entity, financeCategories]);

  const deleteFinanceCategoryEvent = async (financeCategoryId: string) => {
    const financeCategory = getById(financeCategories, financeCategoryId);
    if (window.confirm(`Are you sure you want to delete "${financeCategory.name}"?`)) {
      await api.finance.deleteFinanceCategory(financeCategory);
      dispatch(deleteFinanceCategory(financeCategory));
      navigate("/finances");
    }
  };

  const handleSubmit = async (values: Values, actions: FormikHelpers<Values>) => {
    const financeCategory = {
      ...entity,
      ...{
        name: values.financeCategoryName,
        category_type: values.financeCategoryType,
        order: values.order,
      },
    } as FinanceCategory;
    const action = entity?.id ? api.finance.updateFinanceCategory : api.finance.createFinanceCategory;
    try {
      const response = await action(financeCategory);
      if (entity?.id) {
        dispatch(updateFinanceCategory(financeCategory));
      } else {
        dispatch(createFinanceCategory(response.data));
      }
      actions.setSubmitting(false);
      navigate("/finances");
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  const financeCategoryTypeOptions = Object.keys(FinanceCategoryType).map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmit(values, actions);
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form className="col-lg-3">
          <DisplayError error={formErrors} />

          <div className="mb-3">
            <Field name="financeCategoryName">
              {({ field, meta }: FieldProps) => (
                <label className="form-label" htmlFor="finance-categories-name-input">
                  Name
                  <input
                    type="text"
                    className={`form-control ${meta.error ? "is-invalid" : ""}`}
                    placeholder="Name"
                    id="finance-categories-name-input"
                    data-test="finance-categories-name-input"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                  {meta.touched && meta.error && (
                    <div data-test="finance-categories-name-error" className="invalid-feedback">
                      {meta.error}
                    </div>
                  )}
                </label>
              )}
            </Field>
          </div>

          <div className="mb-3">
            <Field name="order">
              {({ field, meta }: FieldProps) => (
                <label className="form-label" htmlFor="finance-categories-order-input">
                  Order
                  <input
                    type="number"
                    step="1"
                    className={`form-control ${meta.error ? "is-invalid" : ""}`}
                    placeholder="Order"
                    data-test="finance-categories-order-input"
                    id="finance-categories-order-input"
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                  />
                  {meta.touched && meta.error && (
                    <div data-test="finance-categories-order-error" className="invalid-feedback">
                      {meta.error}
                    </div>
                  )}
                </label>
              )}
            </Field>
          </div>

          <div className="mb-3">
            <Field name="financeCategoryType">
              {({ field, meta }: FieldProps) => (
                <div>
                  <label className="form-label" htmlFor="finance-categories-type-input">
                    Type
                    <select
                      className={`form-control ${meta.error ? "is-invalid" : ""}`}
                      data-test="finance-categories-type-input"
                      id="finance-categories-type-input"
                      value={field.value}
                      name={field.name}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                    >
                      <option value="">select an option</option>
                      {financeCategoryTypeOptions}
                    </select>
                    {meta.touched && meta.error && (
                      <div data-test="finance-categories-type-error" className="invalid-feedback">
                        {meta.error}
                      </div>
                    )}
                  </label>
                </div>
              )}
            </Field>
          </div>

          <div className="text-end">
            {isDefined(entity) && (
              <button
                type="button"
                className="me-2 btn btn-outline-danger"
                data-test="finance-categories-delete-button"
                onClick={() => deleteFinanceCategoryEvent(entity?.id as string)}
              >
                Delete
              </button>
            )}

            <button
              data-test="finance-categories-save-button"
              type="submit"
              className="btn btn-outline-primary"
              disabled={!props.isValid || props.isSubmitting}
            >
              Save
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
