import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { number, object, string } from "yup";
import { Currency, FinanceAccount, FinanceAccountType } from "client/data-contracts";
import DisplayError from "components/Common/DisplayError";
import {
  createAccount,
  selectAccounts,
  updateAccount,
} from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { isDefined, Nullish } from "utils/base";
import { convertMoneyToNumber, convertNumberToMoney } from "utils/finances";
import { useNavigate } from "react-router-dom";

interface AccountsFormProps {
  entity: FinanceAccount | undefined;
}

interface Values {
  accountName: string;
  accountCurrency: string;
  accountType: string;
  accountBalance: number;
  displayLevel: number;
  order: number;
}

const validationSchema = object({
  accountName: string().required("Account name is required."),
  accountCurrency: string().required("Currency is required."),
  accountType: string().required("Type is required."),
  accountBalance: string().required(),
  order: number().required().positive().min(0),
  displayLevel: number().required().positive().min(0),
});

export default function FinanceAccountsForm({ entity }: AccountsFormProps): React.JSX.Element {
  const navigate = useNavigate();
  const defaultValues = (entityForm: FinanceAccount | undefined): Values => {
    return {
      accountName: entityForm?.name || "",
      accountCurrency: entityForm?.currency || "",
      accountType: entityForm?.account_type || "",
      accountBalance:
        isDefined(entityForm) && isDefined(entityForm.balance)
          ? convertMoneyToNumber(entityForm.balance)
          : "",
      order: 0,
      displayLevel: entityForm?.display_level || 0,
    } as Values;
  };
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));

  useEffect(() => {
    const values = defaultValues(entity);
    values.order = isDefined(entity) && isDefined(entity.order) ? entity.order : accounts.length;
    setFormValues(values);
  }, [entity, accounts]);

  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    const account = {
      ...entity,
      ...{
        name: values.accountName,
        currency: values.accountCurrency,
        account_type: values.accountType,
        balance: convertNumberToMoney(values.accountBalance),
        order: values.order,
        display_level: values.displayLevel,
      },
    } as FinanceAccount;
    const action = entity?.id ? api.finance.updateFinanceAccount : api.finance.createFinanceAccount;
    try {
      const response = await action(account);
      if (entity?.id) {
        dispatch(updateAccount(response.data));
      } else {
        dispatch(createAccount(response.data));
      }
      navigate("/finances/accounts");
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  const currencyOptions = Object.keys(Currency).map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  const accountTypeOptions = Object.keys(FinanceAccountType).map((item) => (
    <option key={item} value={item}>
      {item}
    </option>
  ));

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmitEvent(values, actions);
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form>
          <>
            <DisplayError error={formErrors} />

            <div className="col-2">
              <div className="mb-3">
                <Field name="accountName">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="accounts-name-input">
                      Account Name
                      <input
                        type="text"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Account Name"
                        id="accounts-name-input"
                        data-test="accounts-name-input"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="accounts-name-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="accountBalance">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="accounts-balance-input">
                      Balance
                      <input
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Balance"
                        data-test="accounts-balance-input"
                        id="accounts-balance-input"
                        name={field.name}
                        value={field.value}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          event.target.value = event.target.value.replace(",", ".");
                          props.setFieldValue("accountBalance", event.target.value);
                        }}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="accounts-balance-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="accountCurrency">
                  {({ field, meta }: FieldProps) => (
                    <div>
                      <label className="form-label" htmlFor="accounts-currency-input">
                        Currency
                        <select
                          className={`form-control ${meta.error ? "is-invalid" : ""}`}
                          data-test="accounts-currency-input"
                          id="accounts-currency-input"
                          value={field.value}
                          name={field.name}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                        >
                          <option value="">select an option</option>
                          {currencyOptions}
                        </select>
                        {meta.touched && meta.error && (
                          <div data-test="accounts-currency-error" className="invalid-feedback">
                            {meta.error}
                          </div>
                        )}
                      </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="accountType">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="accounts-type-input">
                      Type
                      <select
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        data-test="accounts-type-input"
                        id="accounts-type-input"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        <option value="">select an option</option>
                        {accountTypeOptions}
                      </select>
                      {meta.touched && meta.error && (
                        <div data-test="accounts-type-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="order">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="accounts-order-input">
                      Order
                      <input
                        type="number"
                        step="1"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Order"
                        data-test="accounts-order-input"
                        id="accounts-order-input"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="accounts-order-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="displayLevel">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="accounts-display-level-input">
                      Display level
                      <input
                        type="number"
                        step="1"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Display level"
                        data-test="accounts-display-level-input"
                        id="accounts-display-level-input"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="accounts-display-level-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3 text-end">
                <button
                  type="button"
                  className="btn btn-secondary  me-2"
                  onClick={() => navigate("/finances/accounts")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-test="accounts-save-button"
                  disabled={!props.isValid || props.isSubmitting}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
}
