import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import IncomeForm from "components/Finances/Income/IncomeForm";
import { selectIncomeList } from "components/Finances/Income/IncomeSlice";

export default function IncomeEdit(): React.JSX.Element {
  const { incomeId } = useParams<{ incomeId: string }>();
  const incomeList = useSelector(selectIncomeList);
  const income = incomeList.find((item) => item.id === incomeId);

  return (
    <>
      <h3 data-test="income-edit-title">Edit income</h3>
      <IncomeForm entity={income} />
    </>
  );
}
