import React, { useRef } from "react";
import { BookInputSchema, BookSchema } from "client/data-contracts";
import { Nullish } from "utils/base";
import { AxiosError } from "axios";
import api from "utils/api";

interface BookUpdateInfoProps {
  setBook: (book: BookSchema) => void;
  setRequestError: (error: Nullish<AxiosError>) => void;
}

export default function BookUpdateInfo({ setRequestError, setBook }: BookUpdateInfoProps): React.JSX.Element {
  const updateInfoTextAreaRef = useRef<HTMLTextAreaElement>(null);

  async function updateInfoHandler(event: React.FormEvent) {
    event.preventDefault();
    setRequestError(null);
    const inputText = updateInfoTextAreaRef.current?.value ?? "";

    let bookInput;
    try {
      bookInput = JSON.parse(inputText);
    } catch {
      alert("Invalid JSON data");
    }

    // we are updating the current book with the new data
    bookInput.book_url = location.href;

    try {
      const response = await api.books.createOrUpdateBookFromExport(bookInput as BookInputSchema);
      setBook(response.data);
      if (updateInfoTextAreaRef.current) {
        updateInfoTextAreaRef.current.value = "";
      }
    } catch (apiError) {
      setRequestError(apiError as AxiosError);
    }
  }

  return (
    <form>
      <h4>Update info</h4>
      <p className="text-secondary">
        Add information to this book, all fields will be merged when it is possible.
      </p>
      <textarea
        ref={updateInfoTextAreaRef}
        rows={4}
        className="form-control"
        placeholder="Paste book JSON data here..."
      ></textarea>
      <div className="text-end">
        <button
          className="btn btn-primary mt-2"
          type="submit"
          onClick={(event) => {
            updateInfoHandler(event);
          }}
        >
          Update
        </button>
      </div>
    </form>
  );
}
