import React from "react";
import { deleteAccount, selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import getById from "utils/crud";
import { displayMoneyWithCurrency } from "utils/finances";
import { useNavigate } from "react-router-dom";

export default function FinanceAccountsList(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const deleteAccountEvent = async (accountId: string) => {
    const account = getById(accounts, accountId);
    if (window.confirm(`Are you sure you want to delete account "${account.name}"?`)) {
      await api.finance.deleteFinanceAccount(account);
      dispatch(deleteAccount(account));
    }
  };

  const accountRows = accounts.map((item) => (
    <tr key={item.id}>
      <td>{item.name}</td>
      <td>{item.account_type}</td>
      <td>{displayMoneyWithCurrency(item.balance as number, item.currency)}</td>
      <td>{item.display_level}</td>
      <td>{item.order}</td>
      <td>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-primary"
          data-test="accounts-edit-button"
          onClick={() => navigate(`/finances/accounts/edit/${item.id}`)}
        >
          Edit
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-danger"
          data-test="accounts-delete-button"
          onClick={() => deleteAccountEvent(item.id as string)}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <table
      className="table table-responsive table-bordered table-striped table-sm"
      data-test="accounts-table"
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Balance</th>
          <th>Level</th>
          <th>Order</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {accounts.length ? (
          accountRows
        ) : (
          <tr>
            <td colSpan={6}>No data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
