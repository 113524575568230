import React from "react";
import FinanceAccountsList from "components/Finances/FinanceAccounts/FinanceAccountsList";
import DepositAccounts from "components/Finances/Investments/DepositAccounts";
import { useNavigate } from "react-router-dom";

export default function FinanceAccounts(): React.JSX.Element {
  const navigate = useNavigate();
  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-2 d-flex">
          <h3 data-test="accounts-list-header">Accounts</h3>
          <button
            type="button"
            data-test="accounts-create-button"
            className="ms-3 btn btn-sm btn-outline-success ml-auto mt-1"
            style={{ height: "31px" }}
            onClick={() => navigate("/finances/accounts/add")}
          >
            Create
          </button>
        </div>
      </div>
      <FinanceAccountsList />
      <DepositAccounts />
    </>
  );
}
