import { BookSchema } from "client/data-contracts";

export function displayMainTitle(book: BookSchema, language: string = "en"): string {
  let title = book.titles.find((title) => title.language === language);
  if (!title) {
    title = book.titles[0];
  }

  return title?.name ?? "No title";
}

export function displayOtherTitles(book: BookSchema, language: string = "en"): string {
  const mainTitle = displayMainTitle(book, language);
  const titles = book.titles.filter((title) => title.name !== mainTitle).map((title) => title.name);

  return titles.join("; ");
}
