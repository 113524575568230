import React, { useEffect } from "react";
import MoneyStatDisplay from "components/Finances/Common/MoneyStatDisplay";
import IncomeList from "components/Finances/Income/IncomeList";
import { loadIncome, selectMonthlyEarningsStat } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { useNavigate } from "react-router-dom";

export default function IncomeMain(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const monthlyEarningsStat = useAppSelector(selectMonthlyEarningsStat);

  useEffect(() => {
    (async () => {
      const response = await api.finance.getListOfIncome();
      dispatch(loadIncome(response.data));
    })();
  }, [dispatch]);

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-2 d-flex">
          <h3>Income</h3>
          <button
            type="button"
            data-test="income-create-button"
            className="ms-4 btn btn-sm btn-outline-success ml-auto mt-1 d-none d-sm-block"
            style={{ height: "31px" }}
            onClick={() => {
              navigate("/finances/income/add");
            }}
          >
            Create
          </button>
        </div>
        <div
          className="col-12 col-lg-10 text-secondary small text-end text-start pt-1 pb-1"
          data-test="income-stat"
        >
          <MoneyStatDisplay
            roundBigNumber={false}
            showOnlyTotal={false}
            displayPrefix
            stat={monthlyEarningsStat}
          />
        </div>
      </div>
      <IncomeList />
    </>
  );
}
