import React from "react";
import { BookSchema, BookSource, ExternalIdentifier } from "client/data-contracts";
import { displayMainTitle } from "utils/media/book";

function getSearchURL(source: BookSource, book: BookSchema): string {
  if (source === BookSource.Fantlab) {
    const title = displayMainTitle(book, "ru");
    return `https://fantlab.ru/searchmain?searchstr=${encodeURIComponent(title)}`;
  }

  if (source === BookSource.AuthorToday) {
    const title = displayMainTitle(book, "ru");
    return `https://author.today/search?category=works&q=${encodeURIComponent(title)}`;
  }

  if (source === BookSource.Goodreads) {
    const title = displayMainTitle(book, "en");
    return `https://www.goodreads.com/search?search_type=books&q=${encodeURIComponent(title)}`;
  }

  return "";
}

function getBookExternalURL(item: ExternalIdentifier): string {
  if (item.source_name === BookSource.Fantlab) {
    return `https://fantlab.ru/work${item.source_id}`;
  }

  if (item.source_name === BookSource.AuthorToday) {
    return `https://author.today/work/${item.source_id}`;
  }

  if (item.source_name === BookSource.Goodreads) {
    return `https://www.goodreads.com/book/show/${item.source_id}`;
  }

  return "";
}

interface Props {
  book: BookSchema;
  className?: string;
}

export default function BookExternalLinks({ book, className = "" }: Props): React.JSX.Element {
  return (
    <div className={`d-flex gap-2 ${className}`}>
      {Object.values(BookSource)
        .sort()
        .map((source) => {
          const item = book.external_links?.find((x) => x.source_name === source);
          return item ? (
            <a
              key={source}
              href={getBookExternalURL(item)}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-primary"
            >
              [{source[0]}]
            </a>
          ) : (
            <a
              key={source}
              href={getSearchURL(source, book)}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-secondary"
            >
              [{source[0]}]
            </a>
          );
        })}
    </div>
  );
}
