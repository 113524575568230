import React from "react";
import { useNavigate } from "react-router-dom";
import { Currency } from "client/data-contracts";
import { deleteIncome, selectIncomeList } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import getById from "utils/crud";
import { displayMoneyWithCurrency } from "utils/finances";

export default function IncomeList(): React.JSX.Element {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const incomeList = useAppSelector(selectIncomeList);
  const deleteIncomeEvent = async (incomeId: string) => {
    const income = getById(incomeList, incomeId);
    if (window.confirm(`Are you sure you want to delete income "${income.name}"?`)) {
      await api.finance.deleteIncome(income);
      dispatch(deleteIncome(income));
    }
  };

  const incomeRows = incomeList.map((item) => (
    <tr key={item.id}>
      <td>
        {item.name} ({item.associated_account?.name}, {item.associated_account?.currency})
      </td>
      <td>
        {displayMoneyWithCurrency(
          item.earnings as number,
          item.associated_account?.currency as Currency,
          true
        )}
      </td>
      <td>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-primary d-none d-sm-block"
          data-test="income-edit-button"
          onClick={() => {
            navigate(`/finances/income/edit/${item.id}`);
          }}
        >
          Edit
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-success mt-1"
          data-test="income-add-income-button"
          onClick={() => {
            navigate(`/finances/transactions/income/${item.id}/${item.associated_account_id}`);
          }}
        >
          Income
        </button>
        <button
          type="button"
          className="me-2 btn btn-sm btn-outline-danger mt-1 d-none d-sm-block"
          data-test="income-delete-button"
          onClick={() => deleteIncomeEvent(item.id as string)}
        >
          Delete
        </button>
      </td>
    </tr>
  ));

  return (
    <table className="table table-responsive table-bordered table-striped table-sm" data-test="income-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Earnings</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {incomeList.length ? (
          incomeRows
        ) : (
          <tr>
            <td colSpan={6}>No data</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
