/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AuthRequestSchema */
export interface AuthRequestSchema {
  /** Username */
  username: string;
  /** Password */
  password: string;
}

/** Author */
export interface Author {
  /** Names */
  names: LocalizedText[];
  /** Country */
  country: string;
}

/** BookInputSchema */
export interface BookInputSchema {
  /** Url */
  url: string;
  /** Book Url */
  book_url?: string;
  /** Title Ru */
  title_ru?: string;
  /** Title En */
  title_en?: string;
}

/**
 * BookReadingStatus
 * An enumeration.
 */
export enum BookReadingStatus {
  InProgress = "in_progress",
  Done = "done",
  Dropped = "dropped",
}

/** BookSchema */
export interface BookSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Titles */
  titles: LocalizedText[];
  /** Authors */
  authors?: Author[];
  /** Shelf */
  shelf?: string;
  /** Reading History */
  reading_history?: ReadingHistory[];
  /** External Links */
  external_links?: ExternalIdentifier[];
  /** Cover Base64 */
  cover_base64?: string;
  /** Series Id */
  series_id?: string;
  /** Series Position */
  series_position?: number;
}

/**
 * BookSource
 * An enumeration.
 */
export enum BookSource {
  Goodreads = "goodreads",
  Fantlab = "fantlab",
  AuthorToday = "author.today",
}

/**
 * Currency
 * An enumeration.
 */
export enum Currency {
  EUR = "EUR",
  HKD = "HKD",
  KRW = "KRW",
  RUB = "RUB",
  THB = "THB",
  USD = "USD",
}

/** CurrencyExchangeRateListSchema */
export interface CurrencyExchangeRateListSchema {
  /** Items */
  items: CurrencyExchangeRateSchema[];
  /** Page */
  page: number;
  /** Per Page */
  per_page: number;
  /** Count */
  count: number;
}

/** CurrencyExchangeRateSchema */
export interface CurrencyExchangeRateSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** An enumeration. */
  from_currency: Currency;
  /** An enumeration. */
  to_currency: Currency;
  /** Rate */
  rate: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
}

/** DepositAccountListSchema */
export interface DepositAccountListSchema {
  /** Accounts */
  accounts: DepositAccountSchema[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  income_stat: MoneyStat;
}

/** DepositAccountSchema */
export interface DepositAccountSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date: string;
  /**
   * End Date
   * @format date-time
   */
  end_date: string;
  /** Percent */
  percent: number;
  /** Amount */
  amount: number;
  /** An enumeration. */
  currency: Currency;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Account Id */
  account_id: string;
  /** Approximate Total Income */
  approximate_total_income?: number;
  account?: FinanceAccount;
}

/** ExternalIdentifier */
export interface ExternalIdentifier {
  /** An enumeration. */
  source_name: BookSource;
  /** Source Id */
  source_id: string;
}

/** FinanceAccount */
export interface FinanceAccount {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /** An enumeration. */
  currency: Currency;
  /** An enumeration. */
  account_type: FinanceAccountType;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Display Level
   * @default 0
   */
  display_level?: number;
  /**
   * Balance
   * @default 0
   */
  balance?: number;
  /**
   * Order
   * @default 0
   */
  order?: number;
}

/** FinanceAccountSchema */
export interface FinanceAccountSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /** An enumeration. */
  currency: Currency;
  /** An enumeration. */
  account_type: FinanceAccountType;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Display Level
   * @default 0
   */
  display_level?: number;
  /**
   * Balance
   * @default 0
   */
  balance?: number;
  /**
   * Order
   * @default 0
   */
  order?: number;
}

/**
 * FinanceAccountType
 * An enumeration.
 */
export enum FinanceAccountType {
  BANK = "BANK",
  CASH = "CASH",
  INVESTING = "INVESTING",
  OTHER = "OTHER",
}

/** FinanceCategory */
export interface FinanceCategory {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Order
   * @default 0
   */
  order?: number;
  /** An enumeration. */
  category_type?: FinanceCategoryType;
}

/** FinanceCategoryListSchema */
export interface FinanceCategoryListSchema {
  /** Categories */
  categories: FinanceCategorySchema[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  monthly_spending_stat: MoneyStat;
}

/** FinanceCategorySchema */
export interface FinanceCategorySchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /**
   * Order
   * @default 0
   */
  order?: number;
  /** An enumeration. */
  category_type?: FinanceCategoryType;
  /**
   * Amount
   * @default 0
   */
  amount?: number;
}

/**
 * FinanceCategoryType
 * An enumeration.
 */
export enum FinanceCategoryType {
  PRODUCTS = "PRODUCTS",
  RESTAURANTS = "RESTAURANTS",
  TRANSPORT = "TRANSPORT",
  SERVICES = "SERVICES",
  ENTERTAINMENT = "ENTERTAINMENT",
  HOUSE = "HOUSE",
  SHOPPING = "SHOPPING",
  OTHER = "OTHER",
}

/** FinanceGroupIncomeAndExpensesStatisticsSchema */
export interface FinanceGroupIncomeAndExpensesStatisticsSchema {
  /** Income */
  income: IncomeBySourceSchema[];
  /** Expenses */
  expenses: object[];
}

/** FinanceGroupSchema */
export interface FinanceGroupSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Start Date
   * @format date-time
   */
  start_date?: string;
  /**
   * End Date
   * @format date-time
   */
  end_date?: string;
  /** An enumeration. */
  group_type: FinanceGroupType;
  /**
   * Sort Field
   * @format date-time
   */
  sort_field?: string;
  /** Associated Transaction Tags */
  associated_transaction_tags?: TransactionTag[];
  statistics?: FinanceGroupStatistics;
}

/** FinanceGroupStatistics */
export interface FinanceGroupStatistics {
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  all: MoneyStat;
  /** Categories */
  categories: Record<string, MoneyStat>;
  /** Comments */
  comments: Record<string, MoneyStat>;
  /** Tags */
  tags: Record<string, MoneyStat>;
  /** Income */
  income: Record<string, MoneyStat>;
  /**
   * First Transaction Date
   * @format date-time
   */
  first_transaction_date: string;
  /**
   * Last Transaction Date
   * @format date-time
   */
  last_transaction_date: string;
}

/**
 * FinanceGroupType
 * An enumeration.
 */
export enum FinanceGroupType {
  MONTHLY_EXPENSES = "MONTHLY_EXPENSES",
  MONTHLY_INCOME = "MONTHLY_INCOME",
  PERIODICAL = "PERIODICAL",
  DATA_BASED = "DATA_BASED",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Income */
export interface Income {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Owner */
  owner?: string;
  /** Associated Account Id */
  associated_account_id: string;
}

/** IncomeBySourceSchema */
export interface IncomeBySourceSchema {
  /** Date */
  date: string;
  /** Value */
  value: number;
  /** Owner */
  owner?: string;
}

/** IncomeListSchema */
export interface IncomeListSchema {
  /** Income List */
  income_list: IncomeSchema[];
  /**
   * It is an obj for the money statistic.
   * It contains approximate values of earning/spending in default currency.
   * And it contains exact earning/spending amount in all currencies
   */
  monthly_earnings_stat: MoneyStat;
}

/** IncomeSchema */
export interface IncomeSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Name */
  name: string;
  /**
   * Archived
   * @default false
   */
  archived?: boolean;
  /**
   * Is Deleted
   * @default false
   */
  is_deleted?: boolean;
  /** Owner */
  owner?: string;
  /** Associated Account Id */
  associated_account_id: string;
  associated_account?: FinanceAccount;
  /**
   * Earnings
   * @default 0
   */
  earnings?: number;
  /** An enumeration. */
  earnings_currency?: Currency;
}

/** LocalizedText */
export interface LocalizedText {
  /** Name */
  name: string;
  /** Language */
  language: string;
}

/**
 * MoneyStat
 * It is an obj for the money statistic.
 * It contains approximate values of earning/spending in default currency.
 * And it contains exact earning/spending amount in all currencies
 */
export interface MoneyStat {
  /** Default Currency Amount */
  default_currency_amount: number;
  /** All Currencies Amount */
  all_currencies_amount: Record<string, number>;
}

/** ReadingHistory */
export interface ReadingHistory {
  /**
   * Date Start
   * @format date-time
   */
  date_start: string;
  /**
   * Date End
   * @format date-time
   */
  date_end?: string;
  /** Score */
  score?: number;
  /** An enumeration. */
  status: BookReadingStatus;
  /** Comment */
  comment?: string;
}

/** TokenSchema */
export interface TokenSchema {
  /** Access Token */
  access_token: string;
  /** Token Type */
  token_type: string;
}

/** TransactionSchema */
export interface TransactionSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Amount */
  amount: number;
  /**
   * Date
   * @format date-time
   */
  date: string;
  /** An enumeration. */
  type: TransactionType;
  /** Tags */
  tags?: TransactionTag[];
  /** Comment */
  comment?: string;
  /** Equivalent Amount In Usd */
  equivalent_amount_in_usd?: number;
  /** Account Id */
  account_id: string;
  /** Category Id */
  category_id?: string;
  /** From Income Id */
  from_income_id?: string;
  /** To Account Id */
  to_account_id?: string;
  /** An enumeration. */
  to_currency?: Currency;
  /** To Amount */
  to_amount?: number;
  /** An enumeration. */
  currency: Currency;
  from_income?: Income;
  account?: FinanceAccount;
  to_account?: FinanceAccount;
  category?: FinanceCategory;
}

/** TransactionTag */
export interface TransactionTag {
  /** Name */
  name: string;
}

/**
 * TransactionType
 * An enumeration.
 */
export enum TransactionType {
  REGULAR = "REGULAR",
  INCOME = "INCOME",
  TRANSFER = "TRANSFER",
}

/** UserSchema */
export interface UserSchema {
  /** Id */
  id?: string;
  /**
   * Updated
   * @format date-time
   */
  updated?: string;
  /**
   * Related Models
   * @default {}
   */
  related_models?: object;
  /** Username */
  username: string;
  /** Email */
  email: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}
