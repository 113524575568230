import React, { useState } from "react";
import { BookSchema } from "client/data-contracts";
import { Nullish } from "utils/base";
import { AxiosError } from "axios";
import api from "utils/api";

interface BookRawContentProps {
  book: BookSchema;
  setBook: (book: BookSchema) => void;
  setRequestError: (error: Nullish<AxiosError>) => void;
}

export default function BookRawContent({
  book,
  setRequestError,
  setBook,
}: BookRawContentProps): React.JSX.Element {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");

  async function submitEditedContent(event: React.FormEvent) {
    event.preventDefault();

    try {
      const updatedBook = JSON.parse(editedContent);
      const response = await api.books.updateBook(updatedBook);
      setBook(response.data);
      setIsEditing(false);
    } catch (error) {
      if (error instanceof SyntaxError) {
        alert("Invalid JSON data");
      } else {
        setRequestError(error as AxiosError);
      }
    }
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>Raw content</h4>
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={() => {
            setIsEditing(!isEditing);
            setEditedContent(JSON.stringify(book, null, 2));
          }}
        >
          {isEditing ? "Cancel" : "Edit"}
        </button>
      </div>

      {isEditing ? (
        <form onSubmit={submitEditedContent}>
          <textarea
            className="form-control font-monospace"
            rows={20}
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
          />
          <div className="text-end mt-2">
            <button type="submit" className="btn btn-primary">
              Save Changes
            </button>
          </div>
        </form>
      ) : (
        <code>
          <pre>{JSON.stringify(book, null, 2)}</pre>
        </code>
      )}
    </>
  );
}
