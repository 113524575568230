import React from "react";
import FinanceAccountsForm from "components/Finances/FinanceAccounts/FinanceAccountsForm";

export default function FinanceAccountsAdd(): React.JSX.Element {
  return (
    <>
      <h3 data-test="accounts-add-title">Add finance account</h3>
      <FinanceAccountsForm entity={undefined} />
    </>
  );
}
