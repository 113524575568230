import React from "react";
import FinanceAccountsForm from "components/Finances/FinanceAccounts/FinanceAccountsForm";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks";
import { selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";

export default function FinanceAccountsEdit(): React.JSX.Element {
  const { financeAccountId } = useParams<{ financeAccountId: string }>();
  const accounts = useAppSelector(selectAccounts);
  const account = accounts.find((item) => item.id === financeAccountId);
  return (
    <>
      <h3 data-test="accounts-add-title">Edit finance account</h3>
      <FinanceAccountsForm entity={account} />
    </>
  );
}
