export type Nullish<T> = T | null | undefined;
export type NumberFieldValue = number | "";

export function isDefined<T>(value: T | undefined | null): value is T {
  return (value as T) !== undefined && (value as T) !== null;
}

export interface NavOption {
  name: string;
  isActive: boolean;
  link: string;
}
