import type { AxiosError } from "axios";
import type { FieldProps, FormikHelpers, FormikProps } from "formik";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { object, string } from "yup";
import { Income } from "client/data-contracts";
import DisplayError from "components/Common/DisplayError";
import { selectAccounts } from "components/Finances/FinanceAccounts/FinanceAccountsSlice";
import { createIncome, updateIncome } from "components/Finances/Income/IncomeSlice";
import { useAppDispatch, useAppSelector } from "hooks";
import api from "utils/api";
import { Nullish } from "utils/base";
import { accountNameDisplay } from "utils/finances";
import { useNavigate } from "react-router-dom";

interface IncomeFormProps {
  entity: Income | undefined;
}

interface Values {
  incomeName: string;
  incomeOwner: string;
  accountId: string;
}

const validationSchema = object({
  incomeName: string().required("Income name is required."),
  incomeOwner: string(),
  accountId: string().required("Account is required."),
});

export default function IncomeForm({ entity }: IncomeFormProps): React.JSX.Element {
  const navigate = useNavigate();
  const defaultValues = (entityForm: Income | undefined): Values => {
    return {
      incomeName: entityForm?.name || "",
      incomeOwner: entityForm?.owner || "",
      accountId: entityForm?.associated_account_id || "",
    } as Values;
  };
  const dispatch = useAppDispatch();
  const accounts = useAppSelector(selectAccounts);
  const [formErrors, setFormErrors] = useState<Nullish<AxiosError>>(undefined);
  const [formValues, setFormValues] = useState<Values>(defaultValues(undefined));

  useEffect(() => {
    setFormValues(defaultValues(entity));
  }, [entity]);

  const handleSubmitEvent = async (values: Values, actions: FormikHelpers<Values>) => {
    const income = {
      ...entity,
      ...{ name: values.incomeName, owner: values.incomeOwner, associated_account_id: values.accountId },
    } as Income;
    const action = entity?.id ? api.finance.updateIncome : api.finance.createIncome;

    try {
      const response = await action(income);
      if (entity?.id) {
        dispatch(updateIncome(response.data));
      } else {
        dispatch(createIncome(response.data));
      }
      navigate("/finances/income");
    } catch (requestError) {
      setFormErrors(requestError as AxiosError);
      actions.setSubmitting(false);
    }
  };

  const accountOptions = accounts.map((item) => (
    <option key={item.id} value={item.id}>
      {accountNameDisplay(item)}
    </option>
  ));

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={formValues}
      onSubmit={(values: Values, actions: FormikHelpers<Values>) => {
        handleSubmitEvent(values, actions);
      }}
    >
      {(props: FormikProps<Values>) => (
        <Form>
          <>
            <DisplayError error={formErrors} />

            <div className="col-2">
              <div className="mb-3">
                <Field name="incomeName">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="income-name-input">
                      Income Name
                      <input
                        type="text"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Income Name"
                        data-test="income-name-input"
                        id="income-name-input"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="income-name-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="incomeOwner">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="income-owner-input">
                      Owner
                      <input
                        type="text"
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        placeholder="Income Owner"
                        data-test="income-owner-input"
                        id="income-owner-input"
                        name={field.name}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      />
                      {meta.touched && meta.error && (
                        <div data-test="income-name-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3">
                <Field name="accountId">
                  {({ field, meta }: FieldProps) => (
                    <label className="form-label" htmlFor="income-account-id-input">
                      Account
                      <select
                        className={`form-control ${meta.error ? "is-invalid" : ""}`}
                        data-test="income-account-id-input"
                        id="income-account-id-input"
                        value={field.value}
                        name={field.name}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                      >
                        <option value="">select an option</option>
                        {accountOptions}
                      </select>
                      {meta.touched && meta.error && (
                        <div data-test="income-account-id-error" className="invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </label>
                  )}
                </Field>
              </div>

              <div className="mb-3 text-end">
                <button
                  type="button"
                  className="btn btn-secondary  me-2"
                  onClick={() => navigate("/finances/income")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-test="income-save-button"
                  disabled={!props.isValid || props.isSubmitting}
                >
                  Save
                </button>
              </div>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
}
